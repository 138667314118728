<script setup lang="ts">
import { useFiltersStore } from '@/stores/filters';
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import FilterHeader from '@/components/FeatureSearchFilters/FilterHeader.vue';

const filtersStore = useFiltersStore();
const { filters } = storeToRefs(filtersStore);

const props = defineProps({
    value: {
        default: '',
    },
    index: Number,
});

const options = [
    {
        name: 'fuel_type.hybrid',
        value: 'hybrid',
    },
    {
        name: 'fuel_type.electric',
        value: 'electric',
    },
    {
        name: 'fuel_type.gasoline',
        value: 'gasoline',
    },
    {
        name: 'fuel_type.diesel',
        value: 'diesel',
    },
];

const isNoPreferenceChecked = ref<boolean>(
    filters.value['fuel-type'].noPreference
);

const toggleIsNoPreferenceChecked = () => {
    isNoPreferenceChecked.value = !isNoPreferenceChecked.value;
    filters.value['fuel-type'].noPreference = isNoPreferenceChecked.value;
};
</script>

<template>
    <FilterHeader
        :title="$t('filter_fuel_type.title')"
        :sub-title="$t('filter_common.sub_title')"
        :index="index"
    >
    </FilterHeader>

    <div
        class="form__group"
    >
        <div class="row">
            <div
                class="col-6 col-md-3"
                v-for="(option, index) in options"
                :key="option.value"
            >
                <div class="selector">
                    <input
                        class="selector__input"
                        type="checkbox"
                        :id="'selectorRadio' + index"
                        name="selectorGroup"
                        v-model="filters['fuel-type'].value"
                        :value="{
                            title: filters['fuel-type'].toFormatted(
                                option.value
                            ),
                            value: option.value,
                        }"
                        :disabled="
                            !filters['fuel-type'].value.find(
                                (e) => e.value == option.value
                            ) && filters['fuel-type'].value.length >= 3
                        "
                        @change="
                            filters['fuel-type'].noPreference = false;
                            isNoPreferenceChecked = false;
                        "
                    />
                    <label
                        class="selector__tile"
                        :for="'selectorRadio' + index"
                    >
                        <i :class="'as-icon-' + option.value"></i>
                        <span class="selector__tile__label">{{
                            $t(option.name)
                        }}</span>
                    </label>
                </div>
            </div>

            <div class="col-12">
                <div
                    class="form__field_clear"
                    :class="{ checked: isNoPreferenceChecked }"
                    @click="
                        filters['fuel-type'].value =
                            filters['fuel-type'].defaultValue;
                        toggleIsNoPreferenceChecked();
                    "
                >
                    {{ $t('buttons.no_preference') }}
                </div>
            </div>
        </div>
    </div>
</template>
