<script setup lang="ts">
import { inject } from 'vue';
import { AppConfigInjectionKey } from '@/libraries/config';

defineProps({
    id: Number,
    title: String,
    text: String,
    source: String,
    imgSrc: String,
    imgAlt: String,
    imgTitle: String,
});

const config = inject(AppConfigInjectionKey);

const brandingState = config?.forced_settings?.branding || 'default';
</script>

<template>
    <div class="col-12 col-sm-6 col-lg-4">
        <RouterLink
            :to="{ name: 'blog', params: { id: id } }"
            class="card"
            v-if="
                brandingState !== 'produpress' &&
                brandingState !== 'produpress_fr'
            "
        >
            <div class="card__img">
                <img :src="imgSrc" :alt="imgTitle" :title="imgTitle" />
            </div>
            <span class="card__source">{{ source }}</span>
            <h4 class="title">{{ title }}</h4>

            <p class="abstract-text">{{ text }}</p>
        </RouterLink>
        <a
            v-if="
                brandingState == 'produpress' ||
                brandingState == 'produpress_fr'
            "
            :href="source"
            class="card"
            target="_blank"
            rel="noopener noreferrer"
        >
            <div class="card__img">
                <img loading="lazy" :src="imgSrc" :alt="imgTitle" :title="imgTitle" />
            </div>
            <h4 class="title">{{ title }}</h4>

            <p class="abstract-text">{{ text }}</p>
        </a>
    </div>
</template>
