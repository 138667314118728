import { useRouter } from 'vue-router';

const router = useRouter();

let iframeEnabled = false;
let iframeTargetUrl = '';

let lastRouteReceived = '';
let poppedState = false;
let initialRoute = true;

export default function initIframeHandler(
    enabled: boolean,
    target_url: string
) {
    iframeEnabled = enabled;
    iframeTargetUrl = target_url;
}

window.addEventListener('message', (event) => {
    if (event?.data?.routeChangedParent) {
        let route = event.data.routeChangedParent;
        if (route.charAt(0) === '#') {
            route = route.substring(1);
        }
        if (
            lastRouteReceived !== route &&
            router.currentRoute.value.fullPath !== route
        ) {
            router.push(route);
        }
        poppedState = false;
        lastRouteReceived = route;
        initialRoute = false;
    }
});

window.onpopstate = (event) => {
    poppedState = true;
};

export const sendRouteChange = (route: string) => {
    // Don't send message on initial load
    if (initialRoute) {
        initialRoute = false;
        return;
    }

    window.parent.postMessage(
        {
            routeChangedParent: '#' + route,
            routeChangedInteraction: poppedState
                ? 'backButton'
                : 'userInteraction',
        },
        iframeTargetUrl
    );

    poppedState = false;
};

export const sendScrollTop = () => {
    window.parent.postMessage(
        {
            scrollTop: true,
        },
        iframeTargetUrl
    );
};
