import {
    createRouter,
    createWebHistory,
    type Router,
    type RouteRecordRaw,
} from 'vue-router';
import HomeView from '../views/HomeView.vue';

import type { I18n } from 'vue-i18n';
import type { Composer } from 'vue-i18n';

export function setupRouter(i18n: I18n): Router {
    const t = i18n.global.t as Composer<{}, {}, {}, true>['t'];

    // setup routes
    const routes: RouteRecordRaw[] = [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/search-by-brand',
            name: 'search-by-brand',
            component: () => import('../views/SearchByBrandView.vue'),
        },
        {
            path: '/search-by-feature',
            name: 'search-by-feature',
            component: () => import('../views/SearchByFeatureView.vue'),
        },
        {
            path: '/suggested-cars',
            name: 'suggested-cars',
            props: (route) => ({
                isSearchByBrand:
                    router.options.history.state.back == '/search-by-brand',
            }),
            component: () => import('../views/SuggestedCarsView.vue'),
        },
        {
            path: '/suggested-cars/versions/:url_key',
            name: 'suggested-cars-vehicles',
            component: () =>
                import('../views/SuggestedCarsVehicleVersionsView.vue'),
            props: true,
        },
        {
            path: '/compare-cars',
            name: 'compare-cars',
            component: () => import('../views/CompareCarsView.vue'),
        },
        window.location.href.includes('localhost')
            ? {
                  path: '/styleguide',
                  name: 'styleguide',
                  component: () => import('../views/Styleguide.vue'),
              }
            : {
                  path: '/styleguide',
                  name: 'not-found',
                  component: () => import('../views/NotFound.vue'),
                  props: true,
              },
        {
            path: '/blog/:id',
            name: 'blog',
            component: () => import('../views/BlogView.vue'),
        },
        {
            path: '/quote',
            children: [
                {
                    path: 'request',
                    name: 'quote_request',
                    component: () => import('../views/QuoteRequest.vue'),
                },
                {
                    path: 'request-edp',
                    name: 'quote_request-edp',
                    component: () => import('../views/QuoteRequest.vue'),
                    props: { edp: true, comment: false },
                },
                {
                    path: 'success/:generic?',
                    name: 'quote_success',
                    component: () => import('../views/QuoteSuccess.vue'),
                    props: true,
                },
                {
                    path: 'error',
                    name: 'quote_error',
                    component: () => import('../views/QuoteError.vue'),
                },
            ],
        },
        {
            path: '/privacy-policy',
            name: 'privacy',
            component: () => import('../views/PrivacyView.vue'),
        },
        {
            path: t('/terms-and-conditions'),
            name: 'tc',
            component: () => import('../views/TermsView.vue'),
        },
        {
            path: '/vehicle/:url_key',
            name: 'car_details',
            component: () => import('../views/CarDetailsView.vue'),
            props: true,
        },
        {
            path: '/:any(.*)',
            name: 'not-found',
            component: () => import('../views/NotFound.vue'),
            props: true,
        },
    ];

    const router = createRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        routes,
    });

    router.afterEach(() => {
        window.scrollTo(0, 0);
    });

    return router;
}

export default setupRouter;
